<template>
    <div class="notice-wrap">
        <el-breadcrumb separator="/" class="path">
            <el-breadcrumb-item :to="{ path: '/' }" class="path-home"> 首页 </el-breadcrumb-item>
            <el-breadcrumb-item class="path-help">新闻公告</el-breadcrumb-item>
        </el-breadcrumb>
        <div class="notice" v-loading="loading">
            <!-- <div class="menu">
        <div class="title">最新公告</div>
        <div class="item" v-for="item in noticeList" :key="item.id" @click="detil(item.id)">
          <div class="item-name">{{ item.title }}</div>
        </div>
      </div> -->

            <div class="list-wrap">
                <div class="banner">
                    <img src="../../../assets/images/news/banner.jpg" alt="" />
<!--                    <p>新闻公告</p>-->
                </div>
                <!-- <div class="notice-title">商城公告</div>
                <div class="list" v-for="item in noticeList" :key="item.id" @click="detil(item.id)">
                    <div class="item">{{ item.title }}</div>
                    <div class="info">
                        <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                    </div>
                </div> -->
                <!-- 导航栏切换效果 -->
                <!-- <div class="main">
                    <ul class="tab">
                        <li @click="fristli()"><a href="#content1">品牌授权</a></li>
                        <li><a href="#content2">商城公告</a></li>
                        <li><a href="#content3">放假通知</a></li>
                    </ul>
                    <div id="content1">
                        <div class="news" v-for="item in brandList" :key="item.id" @click="detil(item.id)">
                            <img v-if="item.image" :src="$imgUrlTo(item.image)" alt="" />
                            <img v-else src="~img/news/content.png" alt="" />
                            <div class="news_time">
                                <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                            </div>
                            <div class="news_title">{{ item.title }}</div>
                        </div>
                    </div>
                    <div id="content2">
                        <div class="news" v-for="item in noticeList" :key="item.id" @click="detil(item.id)">
                            <img v-if="item.image" :src="$imgUrlTo(item.image)" alt="" />
                            <img v-else src="~img/news/content.png" alt="" />
                            <div class="news_time">
                                <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                            </div>
                            <div class="news_title">{{ item.title }}</div>
                        </div>
                    </div>
                    <div id="content3">
                        <div class="news" v-for="item in holidayList" :key="item.id" @click="detil(item.id)">
                            <img v-if="item.image" :src="$imgUrlTo(item.image)" alt="" />
                            <img v-else src="~img/news/content.png" alt="" />
                            <div class="news_time">
                                <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                            </div>
                            <div class="news_title">{{ item.title }}</div>
                        </div>
                    </div>
                </div> -->

                <!-- element-ui 官网 -->
                <div id="app">
                    <el-tabs type="border-card" style="padding: 0px">
                        <el-tab-pane class="content_text">
                            <span slot="label"> 品牌授权</span>
                            <div class="news" v-for="item in brandList" :key="item.id" @click="detil(item.id)">
                                <img v-if="item.image" :src="$imgUrlTo(item.image)" alt="" />
                                <img v-else src="~img/news/content.png" alt="" />
                                <div class="news_time">
                                    <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                                </div>
                                <div class="news_title">{{ item.title }}</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="商城公告" class="content_text">
                            <div class="news" v-for="item in noticeList" :key="item.id" @click="detil(item.id)">
                                <img v-if="item.image" :src="$imgUrlTo(item.image)" alt="" />
                                <img v-else src="~img/news/content.png" alt="" />
                                <div class="news_time">
                                    <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                                </div>
                                <div class="news_title">{{ item.title }}</div>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="放假通知" class="content_text">
                            <div class="news" v-for="item in holidayList" :key="item.id" @click="detil(item.id)">
                                <img v-if="item.image" :src="$imgUrlTo(item.image)" alt="" />
                                <img v-else src="~img/news/content.png" alt="" />
                                <div class="news_time">
                                    <div class="time">{{ $util.timeStampTurnTime(item.create_time) }}</div>
                                </div>
                                <div class="news_title">{{ item.title }}</div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
            </div>
            <!-- 品牌授权、新闻公告、放假通知 -->
            <!-- <div class="goods-bot">
                <el-radio-group v-model="tabCheckValue">
                    <el-radio-button :label="item" :key="this" v-for="(item, index) in tabCheck">{{ item }}</el-radio-button>
                </el-radio-group>
                <div class="goods-con">
                    <div class="goods-detail-img" v-if="tabCheckValue == tabCheck[0]">
                        <div v-html="goods.goods_content"></div>
                    </div>
                    <div class="goods-detail-goods" style="padding-top: 20px" v-if="tabCheckValue == tabCheck[1]">
                        <goods-list :data="goodsSeemList" :pageSize="15" :showpage="false"></goods-list>
                    </div>
                    <div class="goods-detail-commend" style="padding-top: 20px" v-if="tabCheckValue == tabCheck[2]">
                        <goods-list :data="goodsSeemGrandList" :pageSize="15" :showpage="false"></goods-list>
                    </div>
                </div>
            </div> -->
        </div>
        <div class="pager">
            <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页" :current-page.sync="queryinfo.page" :page-size.sync="queryinfo.page_size" @size-change="handlePageSizeChange" @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
        </div>
    </div>
</template>

<script>
import { noticesList } from "@/api/cms/notice"
export default {
    name: "notice",
    components: {},
    data: () => {
        return {
            queryinfo: {
                page: 1,
                page_size: 10,
                receiving_type: "web"
                // parent_id:''
            },
            brandList: [],
            noticeList: [],
            holidayList: [],
            total: 0,
            loading: true
        }
    },
    created() {
        this.getList()
        this.fristli()
    },
    methods: {
        detil(id) {
            this.$router.push({ path: "/cms/notice-" + id })
        },
        getList() {
            noticesList(this.queryinfo)
                .then((res) => {
                    if (res.code == 0 && res.data) {
                        // this.noticeList = res.data.list
                        this.brandList = res.data.list[0]
                        console.log(res.data.list)
                        this.noticeList = res.data.list[1]
                        this.holidayList = res.data.list[2]
                        this.total = res.data.count
                    }
                    this.loading = false
                })
                .catch((err) => {
                    this.loading = false
                    this.$message.error(err.message)
                })
        },
        handlePageSizeChange(newsize) {
            this.queryinfo.page_size = newsize
            this.getList()
        },
        handleCurrentPageChange(newnum) {
            this.queryinfo.page = newnum
            this.getList()
        },
        fristli() {
            console.log("刷新页面")
        }
    }
}
</script>
<style lang="scss" scoped>
.notice-wrap {
    background: #ffffff;
    .path {
        padding: 15px 0;
    }
}
.notice {
    background-color: #ffffff;
    // min-height: 300px;
    position: relative;
    display: flex;
    .menu {
        width: 210px;
        min-height: 300px;
        background: #ffffff;
        border: 1px solid #f1f1ff;
        flex-shrink: 0;
        .title {
            padding-left: 16px;
            background: #f8f8f8;
            font-size: $ns-font-size-base;
            height: 40px;
            cursor: pointer;
            color: #666666;
            display: flex;
            align-items: center;
        }
        .item-name {
            font-size: $ns-font-size-base;
            cursor: pointer;
            line-height: 40px;
            border-top: 1px solid #f1f1f1;
            padding-left: 25px;
            padding-right: 10px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            height: 40px;
            background: #ffffff;
            color: #666666;
            &:hover {
                color: $base-color;
            }
        }
        .active {
            color: $base-color;
        }
    }
    .list-wrap {
        padding: 0 40px;
        margin-left: 27px;
        border: 1px solid #f1f1f1;
        width: 100%;
        margin-bottom: 10px;
        .notice-title {
            padding: 37px 0 20px 0;
            font-size: 18px;
            border-bottom: 1px dotted #e9e9e9;
        }
        .banner {
            position: relative;
            width: 1400px;
            // background: url(../../../assets/images/news/banner.png) no-repeat;
            background-repeat: no-repeat;
            background-size: cover;
            p {
                position: absolute;
                left: 50%;
                top: 100px;
                margin-left: -2em;
                font-size: 20px;
                text-align: center;
                color: #ffffff;
            }
        }
        .list {
            display: flex;
            justify-content: space-between;
            align-items: center;
            &:last-of-type {
                border-bottom: initial;
            }
            &:nth-child(2) {
                margin-top: 10px;
            }
            .item {
                font-size: $ns-font-size-base;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                cursor: pointer;
                padding: 3px 0;
                &:hover {
                    color: $base-color;
                }
            }
            .info {
                display: flex;
                font-size: $ns-font-size-base;
                .time {
                    margin-right: 10px;
                }
            }
        }
    }
}
.page {
    text-align: center;
}
.path-help {
    color: #ff850f;
}
// tab切换效果代码
.main {
}

.tab {
    margin: 0;
    padding: 0;
    overflow: hidden;
    list-style-type: none;
    margin-left: 40%;
    margin-top: 20px;
    :nth-child(3) {
        border-right: none;
    }
}

.tab li {
    float: left;
    border-right: 1px solid #a6a7aa;
    margin-top: 10px;
}

.tab li a {
    text-decoration: none;
    color: #606266;
    display: inline-block;
    width: 95px;
    text-align: center;
}
.content_text {
    .news {
        float: left;
        margin-right: 19px;
        margin-top: 20px;
        img {
            width: 335px;
            height: 240px;
        }
        .news_time {
            margin-top: 10px;
            color: #606266;
            font-size: 14px;
        }
        .news_title {
            font-weight: bold;
            font-size: 16px;
        }
    }
    :nth-child(4n) {
        margin-right: 0px;
    }
}
::v-deep .el-tabs__nav-scroll {
    width: 21% !important;
    margin: 0 auto !important;
    background-color: #fff !important;
}
::v-deep .el-tabs__nav-wrap {
    background-color: #fff !important;
}
::v-deep .el-tabs--border-card {
    border: none;
    box-shadow: none;
}
::v-deep .el-tabs__content {
    padding: 0px;
}
</style>
